import style from './Cards.module.css'
import classNames from 'classnames/bind'
import Link from 'next/link'

export default function Cards () {
  const c = classNames.bind(style)

  return (
    <div className={c('cards')}>
      <section>
        <h3>Learn more</h3>
        <div>
          <ul>
            <li>
              <Link href='/sg/contact'>
                <a className={c('card')}>
                  <span className={c('category')}>About</span>
                  <h4>Visit our new Silver Bullion Shop at Millenia Walk from November 17th</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/contact_photo.jpg')}&w=384&q=75`} alt='Silver Bullion Shop' />
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/why-should-i-save-in-gold'>
                <a className={c('card')}>
                  <span className={c('category')}>Learning</span>
                  <h4>Should I be putting my savings into gold? Is it safe?</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/goldbars.jpg')}&w=384&q=75`} alt='Why should I be putting my savings into gold?' />
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/gold-versus-inflation'>
                <a className={c('card')}>
                  <span className={c('category')}>Learning</span>
                  <h4>Protect yourself against Inflation with Savings in Gold</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/goldbars.jpg')}&w=384&q=75`} alt='Gold versus inflation' />
                </a>
              </Link>
            </li>
            <li>
              <Link href='/explore'>
                <a className={c('card')}>
                  <span className={c('category')}>Explore</span>
                  <h4>Our Gold Savings Account is always fully backed by fine gold.</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/og_goldsavingsaccount.png')}&w=384&q=75`} alt='Gold Savings Account' />
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}
