import style from './Comparison.module.css'
import classNames from 'classnames/bind'
import Link from 'next/link'
import Logo from '../Logo'
import { useTranslation } from 'react-i18next'

export default function Comparison () {
  // eslint-disable-next-line no-unused-vars
  const [t, i18n] = useTranslation('main')
  const locale = i18n.language
  const c = classNames.bind(style)

  const pc = n => ((n / 100) || 0).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })

  return (
    <div className={c('comparison')}>
      <section>
        <h3>How do we compare?</h3>
        <div>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th className={c('gsa')}><Logo width={30} /><br />Gold Savings<br />Account</th>
                <th>Physical</th>
                <th>UOB Gold<br />Savings Account</th>
                <th>BullionStar<br />BSP</th>
                <th>GLD ETF</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{pc(100)} Backed by Real Gold</th>
                <td className={c('yes')} aria-label='yes' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
              </tr>
              <tr>
                <th>Publicly Auditable Gold<br />with <Link href='/explore'><a>Online Photo Explorer</a></Link></th>
                <td className={c('yes')} aria-label='yes' />
                <td>n.a.</td>
                <td className={c('no')} aria-label='no' />
                <td className={c('no')} aria-label='no' />
                <td className={c('no')} aria-label='no' />
              </tr>
              <tr>
                <th>Instant Liquidity (24/7)</th>
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('yes')} aria-label='yes' />
              </tr>
              <tr>
                <th>Max {pc(2)} Premium</th>
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
              </tr>
              <tr>
                <th>Regular 3rd-Party Audits</th>
                <td className={c('yes')} aria-label='yes' />
                <td>n.a.</td>
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
              </tr>
              <tr>
                <th>Fully Insured</th>
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
                <td className={c('no')} aria-label='no' />
                <td className={c('yes')} aria-label='yes' />
                <td className={c('no')} aria-label='no' />
              </tr>
              <tr>
                <th>Buy/Sell Spread</th>
                <td>{pc(2)}</td>
                <td>{pc(1.3)} - {pc(60)}</td>
                <td>&lt;{pc(1)}</td>
                <td>{pc(3.85)}</td>
                <td>&lt;{pc(1)}</td>
              </tr>
              <tr>
                <th>Minimum Purchase</th>
                <td>{(0.01).toLocaleString(locale, { maximumFractionDigits: 2 })}g</td>
                <td>1g</td>
                <td>5g</td>
                <td>1g</td>
                <td>1 share</td>
              </tr>
              <tr>
                <th>Annual Fee</th>
                <td>{pc(0.35)}</td>
                <td>n.a.</td>
                <td>{pc(0.25)}</td>
                <td>{pc(0.09)}</td>
                <td>{pc(0.4)}</td>
              </tr>
              <tr>
                <th>Redemption for Physical</th>
                <td>from 2g</td>
                <td>n.a.</td>
                <td className={c('no')} aria-label='no' />
                <td>from 100g</td>
                <td className={c('no')} aria-label='no' />
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>&nbsp;</th>
                <td>
                  <Link href='/signup'><button className='goldenshimmer'>Sign up today</button></Link><br />
                  <span className={c('small')}>Get 100mg of FREE gold. <Link href='/sg/promotion'><a>T&amp;C's apply</a></Link></span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  )
}
