import style from './Newsletter.module.css'
import formStyle from '../styles/form.module.css'

import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { LayoutContext } from './sg/Layout'
import Loading from './Loading'
import useAuth from '../hooks/useAuth'
import { useRef, useContext, useState } from 'react'
import { event } from '../helpers/analytics'

export default function Newsletter ({ context }) {
  const [t] = useTranslation('main')
  const c = classNames.bind(style)
  const cf = classNames.bind(formStyle)
  const [loading, setLoading] = useState()
  // eslint-disable-next-line no-unused-vars
  const [layoutContext, setLayoutContext] = useContext(context || LayoutContext)
  const { isAuthed } = useAuth()

  const formElem = useRef()
  const firstNameElem = useRef()
  const emailElem = useRef()

  const handleSubmit = e => {
    e.preventDefault()

    const subscribe = async () => {
      setLoading(true)
      const response = await window.fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName: firstNameElem.current.value.trim(),
          email: emailElem.current.value.trim()
        })
      })
      if (response.ok) {
        setLayoutContext({ success: t('newsletter-subscribe-success', 'Thank you. We\'ll keep you updated.') })
        formElem.current.setAttribute('novalidate', '')
        formElem.current.reset()
        event({ action: 'newsletter', category: 'signup', label: 'success', value: 1 })
      } else {
        setLayoutContext({ message: t('newsletter-subscribe-error', 'Something went wrong. Please try again later.') })
      }
      setLoading(false)
    }

    formElem.current.removeAttribute('novalidate')

    if (formElem.current.checkValidity()) {
      subscribe()
    }
  }

  return !isAuthed ? (
    <form ref={formElem} noValidate onSubmit={handleSubmit}>
      <div className={c('newsletter')}>
        <div>
          <h2>{t('newsletter-title', 'Interested to learn more?')}</h2>
        </div>
        <div className={c('nobreak') + ' ' + cf('inputfield')}>
          <input ref={firstNameElem} minLength={2} required autoComplete='given-name' placeholder={t('newsletter-firstname-placeholder', 'Your first name')} />
        </div>
        <div className={c('nobreak') + ' ' + cf('inputfield')}>
          <input ref={emailElem} autoComplete='email' required type='email' placeholder={t('newsletter-email-placeholder', 'Your email')} />
        </div>
        <div>
          <button type='submit'>{loading ? <Loading /> : t('newsletter-subscribe-button', 'Subscribe')}</button>
        </div>
      </div>
    </form>
  ) : null
}
