import style from './index.module.css'

import { getLayout } from '../../components/sg/Layout'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { useState, useEffect } from 'react'
import Cards from '../../components/sg/Cards'
import Comparison from '../../components/sg/Comparison'
import Footer from '../../components/sg/Footer'
import Image from 'next/image'
import Link from 'next/link'
import Logo from '../../components/Logo'
import Router from 'next/router'
import { defaultCurrency } from '../../hooks/usePrices'
import NumberCount from '../../components/NumberCount'
import { sp500, uraResidential, gold, meta } from '../../helpers/economicData'

let timer

export async function getServerSideProps ({ req: { headers: { 'x-vercel-ip-country': ipCountry } } }) {
  return { props: { ipCountry: ipCountry || 'SG' } }
}

export default function Index ({ ipCountry }) {
  const [t, i18n] = useTranslation('main')
  const c = classNames.bind(style)
  const locale = i18n.language

  const [now, setNow] = useState(new Date())
  const [startYear, setStartYear] = useState(2001)
  const [period, setPeriod] = useState(new Date().getFullYear() - 2001 + 1)
  const [amount, setAmount] = useState(10000)

  const filterFunc = (series, add) => series.filter(s => s.year >= startYear && s.year < startYear + period).reduce((a, v) => a * (v.value / 100 + 1), amount) - amount
  const sp500Value = filterFunc(sp500)
  const uraResidentialValue = filterFunc(uraResidential, true)
  const goldValue = filterFunc(gold)
  const minValue = Math.min(sp500Value, uraResidentialValue, goldValue, 0)
  const maxValue = Math.max(sp500Value, uraResidentialValue, goldValue, 0)
  const percent = (maxValue - minValue) / 180
  const basePercent = Math.max(-minValue / percent, 0)
  const sp500Percent = Math.abs(sp500Value) / percent
  const uraResidentialPercent = Math.abs(uraResidentialValue) / percent
  const goldPercent = Math.abs(goldValue) / percent
  const sp500Top = sp500Value < 0 ? basePercent - sp500Percent : basePercent
  const uraResidentialTop = uraResidentialValue < 0 ? basePercent - uraResidentialPercent : basePercent
  const goldTop = goldValue < 0 ? basePercent - goldPercent : basePercent

  useEffect(() => {
    console.log('3D CSS phone art based on work from Grzegorz Witczak: https://codepen.io/Wujek_Greg/pen/LmrweG')
    timer = setInterval(() => setNow(new Date()), 1000)
    return () => clearInterval(timer)
  }, [])

  const handleInstallClick = e => {
    if (window.isAndroid && window.deferredInstallPrompt) {
      e.preventDefault()
      e.stopPropagation()
      Router.push('/install')
    }
  }

  return (
    <>
      <section className={c('homescreen')}>
        <div className={c('logo')}>
          <Logo width={52} />
          <h1>{t('home-goldsaver', 'Gold Savings Account')}</h1>
        </div>
        <div className={c('maininfo')}>
          <h3>{t('home-goldsaver-title', 'Save smart, save in gold')}</h3>
          <p>
            {t('home-goldsaver-slogan1', 'Buy gold to protect against inflation')}
            <br />
            {t('home-goldsaver-slogan2', 'whenever you think the price is right.')}
          </p>
          <div className={c('promo')}>
            <Link href='/signup'>
              <button
                className='goldenshimmer'
                onClick={handleInstallClick}
                title={t('home-startsaving-button-title', 'Navigate to your portfolio')}
              >
                {t('home-startsaving-button', 'Start saving in gold today')}
              </button>
            </Link>
            {ipCountry === 'SG' && <div className={c('singpass')}><Link href='/signup'><a>Sign up with <img src='/images/singpass.svg' width='68' alt='Singpass' /></a></Link></div>}
            <p className={c('subtext')}>
              <img src='/images/gold.png' alt={t('home-goldbar-alt', 'Gold bar')} />
              <b>{t('home-startsaving-promo', 'Get 100mg of FREE gold')}*</b>
              <br />
              {t('home-startsaving-tease', 'Fully backed by real gold, safe, simple and transparent.')}
              <br />
              <span className={c('termsnote')}>* <Link href='/sg/promotion'><a>{t('home-startsaving-termsapply', 'Terms & conditions apply')}</a></Link></span>
            </p>
          </div>
        </div>

        <div className={c('partnerlogos')}>
          <a href='https://www.aspial.com' target='_blank' rel='noopener noreferrer'>
            <Image src='/images/aspial-logo.png' width={90} height={41} alt='Aspial' />
          </a>
          <a href='https://silverbullion.com.sg' target='_blank' rel='noopener noreferrer'>
            <Image src='/images/silver-bullion-logo.svg' width={130} height={42} alt='Silver Bullion' />
          </a>
        </div>

        <div className={c('phone')}>
          <div className={c('phone-back')}>
            <div className={c('phone-left-side')}>
              <div className={c('phone-antena')} />
              <div className={c('phone-button', 'top')} />
              <div className={c('phone-button')} />
              <div className={c('phone-button', 'bottom')} />
              <div className={c('phone-antena', 'bottom')} />
            </div>
            <div className={c('phone-bottom')}>
              <div className={c('phone-antena')} />
              <div className={c('bottom-speaker')}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={c('phone-screw')}>
                <div />
              </div>
              <div className={c('phone-charger')} />
              <div className={c('phone-screw', 'right')}>
                <div />
              </div>
              <div className={c('bottom-speaker', 'right')}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
              <div className={c('phone-antena', 'right')} />
            </div>
          </div>
          <div className={c('phone-screen')} />
          <div className={c('phone-display')}>
            <div className={c('phone-notch')}>
              <div className={c('phone-speaker')} />
            </div>
            <div className={c('display-content')}>
              <div className={c('notifications-bar')}>
                <div className={c('time')}>{now.toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit', hour12: false })}</div>
                <div className={c('range')} />
                <div className={c('wifi')} />
                <div className={c('battery')} />
              </div>
              <iframe className={c('content')} src='./price' scrolling='no' />
              <div className={c('home-button')} />
            </div>
          </div>
          <div className={c('phone-reflections')}>
            <div className={c('reflection-1')} />
            <div className={c('reflection-2')} />
            <div className={c('reflection-3')} />
            <div className={c('reflection-4')} />
            <div className={c('reflection-5')} />
            <div className={c('reflection-6')} />
            <div className={c('reflection-7')} />
          </div>
        </div>

        <div className={c('bubble')}>
          <span>Historically<br />gold gained</span>
          <span className={c('big')}>8</span>
          <span>on average per year<br />since 1970</span>
        </div>
      </section>

      <section className={c('benefits')}>
        <div>
          <h3>Why use the Gold Savings Account?</h3>
          <div className={c('split')}>
            <ul>
              <li>
                <div>
                  <Image src='/images/goldbacked.svg' width={48} height={48} alt='100% gold backed' />
                  <h4>{(1).toLocaleString(locale, { style: 'percent' })} backed by real gold</h4>
                </div>
                <p>Unlike a bank account, we make sure that every gram you buy is fully backed by fine, allocated gold bars, safely insured and stored in our vaults.</p>
              </li>
              <li>
                <div>
                  <Image src='/images/app.svg' width={48} height={48} alt='Instant liquidity' />
                  <h4>Instant liquidity 24/7</h4>
                </div>
                <p>Buy, sell, store, stack and trade gold right from your phone and around the clock starting at {(0.01).toLocaleString(locale, { maximumFractionDigits: 2 })}g of gold and up to {(200000).toLocaleString(locale, { style: 'currency', currency: 'SGD', currencyDisplay: 'symbol', minimumFractionDigits: 0, maximumFractionDigits: 0 })} per day.</p>
              </li>
              <li>
                <div>
                  <Image src='/images/licensed.svg' width={48} height={48} alt='Licensed dealer' />
                  <h4>Licensed precious metals dealer</h4>
                </div>
                <p>Regulated by the Singapore Ministry of Law for the purpose of AML &amp; CFT (License number PS20190001043)<br />We are <a href='/assets/GSA-Shariah-Compliance.pdf' target='_blank' rel='noopener noreferrer'>certified Shariah compliant</a>.</p>
              </li>
              <li>
                <div>
                  <Image src='/images/technology.svg' width={48} height={48} alt='Secured connection' />
                  <h4>Latest SSL SHA-256 2048 bit encryption</h4>
                </div>
                <p>Using the latest technology and data privacy processes to safe-keep your account.</p>
              </li>
            </ul>
            <ul>
              <li>
                <div>
                  <Image src='/images/auditable.svg' width={48} height={48} alt='Publicly auditable' />
                  <h4>Publicly auditable gold backing</h4>
                </div>
                <p>Every bar of our gold backing and all its movements are transparently tracked and visible with photos in our <Link href='/explore'><a>Online Gold Explorer</a></Link>.</p>
              </li>
              <li>
                <div>
                  <Image src='/images/lowfees.svg' width={48} height={48} alt='Low fees' />
                  <h4>Enjoy low fees</h4>
                </div>
                <p>Both our gold premium and buy/sell spread are only {(0.02).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })}. Enjoy an annual storage fee of only {(0.0035).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })} with a minimum charge of 0.1g per year</p>
              </li>
              <li>
                <div>
                  <Image src='/images/deposits.svg' width={48} height={48} alt='Easy transactions' />
                  <h4>Easy SGD deposits and withdrawals</h4>
                </div>
                <p>Use PayNow, bank transfer or credit card to deposit and enjoy same-workday withdrawals to your registered bank account before 4pm SGT.</p>
              </li>
              <li>
                <div>
                  <Image src='/images/redemption.svg' width={48} height={48} alt='Redeemable' />
                  <h4>Redemption for physical gold</h4>
                </div>
                <p>Redeem your gold holding for physical gold in our <Link href='/sg/contact'><a>Bullion Shop in Singapore</a></Link> from on 2 grams.</p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={c('video')}>
        <div>
          <video poster='/images/intro_poster.jpg' controls>
            <source src='/assets/goldsavingsaccount_intro.mp4' type='video/mp4' />
            <div className={c('videofallback')}>
              {t('home-noembedvideo', 'Your browser doesn\'t support our embedded introduction video, but you can download it and watch it in your favorite video player.')}
              <br /><br />
              <a href='/assets/goldsavingsaccount_intro.mp4' download>{t('home-noembedvideo-download', 'Download the intro video')}</a>
            </div>
          </video>
        </div>
        {t('home-introvideo-description', 'Learn about the Gold Savings Account in one minute')}
        <br /><br />
        <Link href='/signup'><button className='goldenshimmer'>{t('home-introvideo-startsaving-button', 'Start saving gold now')}</button></Link> <Link href='/sg/tour'><button className='light'>{t('home-introvideo-takeatour', 'Take a quick tour')}</button></Link>
      </section>

      <section className={c('performance')}>
        <div>
          <h3>{t('home-historicgains-header', 'Historic gains for gold till ')} {meta.maxYear + 1}</h3>
          <p>{t('home-historicgains-description', 'As gold is uncorrelated to other major asset classes, it adds stability to any investment portfolio.')}</p>
          <div className={c('savingsChart')}>
            <div className={c('zeroline')} style={{ bottom: basePercent + 'px' }} />
            <div id='uraResidential' className={c('savingsBar', { up: uraResidentialValue > 0 })} style={{ height: uraResidentialPercent + 'px', marginBottom: uraResidentialTop + 'px' }}>
              <span><NumberCount significantDigits={2} number={uraResidentialValue} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
            <div id='sp500' className={c('savingsBar', { up: sp500Value > 0 })} style={{ height: sp500Percent + 'px', marginBottom: sp500Top + 'px' }}>
              <span><NumberCount significantDigits={2} number={sp500Value} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
            <div id='gold' className={c('savingsBar', 'gold', { up: goldValue > 0 })} style={{ height: goldPercent + 'px', marginBottom: goldTop + 'px' }}>
              <span><NumberCount significantDigits={2} number={goldValue} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></span>
            </div>
          </div>
          <div className={c('savingsLabels')}>
            <label htmlFor='uraResidential'>Singapore Residential Property</label>
            <label htmlFor='sp500'>S&amp;P 500 </label>
            <label htmlFor='gold'>Gold</label>
          </div>
          <div className={c('savingsControl')}>
            <div>
              <label htmlFor='startYear'>{t('home-historicgains-startyear-label', 'Start year')}:</label> <output>{startYear}</output>
              <input type='range' min={meta.minYear} max={meta.maxYear} value={startYear} onChange={e => { setStartYear(parseInt(e.target.value, 10)); setPeriod(meta.maxYear - parseInt(e.target.value, 10) + 1) }} id='startYear' />
            </div>
            <div>
              <label htmlFor='amount'>{t('home-historicgains-investmentamount-label', 'Invested amount')}:</label> <output><NumberCount significantDigits={2} number={amount} options={{ style: 'currency', currency: defaultCurrency, currencyDisplay: 'symbol' }} /></output>
              <input type='range' min={2000} max={500000} step={2000} value={amount} onChange={e => setAmount(parseInt(e.target.value, 10))} id='amount' />
            </div>
          </div>
          <div className={c('sourcenote')}>{t('home-historygains-source', 'Source: URA Property Index, Standard and Poor\'s Financial Services LLC, Silver Bullion Pte Ltd')}</div>
        </div>
      </section>

      <Comparison />
      <Cards />
      <Footer />
    </>
  )
}

Index.getLayout = getLayout
