import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

const dimensions = [
  {
    base: 1000000000,
    short: i18next.t('billionshorthand', { defaultValue: 'B', ns: 'numbers' }) || 'B'
  },
  {
    base: 1000000,
    short: i18next.t('millionshorthand', { defaultValue: 'M', ns: 'numbers' }) || 'M'
  },
  {
    base: 1000,
    short: i18next.t('thousandsshorthand', { defaultValue: 'K', ns: 'numbers' }) || 'K'
  },
  {
    base: 1,
    short: ''
  }
]

export default class NumberCount extends React.PureComponent {
  static shortenNumber (number, significantDigits = 6, options = {}) {
    const absolute = Math.abs(number)
    const { base, short } = dimensions.find(d => absolute >= d.base * (Math.pow(10, significantDigits))) || { base: 0 }
    if (base) {
      return (Math.round(number / (base / 10)) / 10).toLocaleString(i18next.language, Object.assign(options, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      })) + short
    } else {
      return number.toLocaleString(i18next.language, options)
    }
  }

  render () {
    const formatted = this.props.number.toLocaleString(i18next.language, this.props.options)
    const shortened = NumberCount.shortenNumber(this.props.number, this.props.significantDigits, this.props.options)
    return <span title={formatted + (this.props.title ? ' ' + this.props.title : '')}>{shortened}</span>
  }
}

NumberCount.propTypes = {
  number: PropTypes.number.isRequired,
  significantDigits: PropTypes.number,
  options: PropTypes.object,
  title: PropTypes.string
}
