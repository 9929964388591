import style from './Footer.module.css'

import classNames from 'classnames/bind'
import Link from 'next/link'
import Newsletter from '../Newsletter'

export default function Layout () {
  const c = classNames.bind(style)

  return (
    <>
      <Newsletter />
      <footer>
        <div className={c('footer')}>
          <div className={c('logo')}>
            <Link href='/sg'><a><img src='/images/logo-icon-gold.svg' loadinbg='lazy' alt='Gold Savings Account' /></a></Link>
          </div>
          <div className={c('nav')}>
            <nav>
              <Link href='/sg/contact'><a>Contact Us</a></Link>
              <Link href='/sg/terms'><a>Terms &amp; Conditions</a></Link>
              <Link href='/sg/privacy'><a>Privacy Policy</a></Link>
            </nav>
            <p>
              © {new Date().getFullYear()} Silver Bullion Pte Ltd. All rights reserved. Gold Savings Account logos and names are registered trademarks.<br />
              PSPMA License PS20190001043
            </p>
          </div>
          <div className={c('social')}>
            <ul>
              <li>
                <a href='https://www.facebook.com/silverbullionsg' title='Facebook' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-facebook.svg' loading='lazy' alt='Follow us on Facebook' />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/silverbullionsg/' title='Instagram' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-instagram.svg' loading='lazy' alt='Follow us in Instagram' />
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/c/SilverBullion' title='Youtube' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-youtube.svg' loading='lazy' alt='Subscribe to our SBTV YouTube channel' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
